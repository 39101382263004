/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  width: 100%;
  //height: 100vh;
  //overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-family: 'Custom', Fallback, sans-serif !important;
}

h1,h2,h3,h4{
  font-family: 'Custom', Fallback, sans-serif !important;
}

@font-face {
  font-family: 'Custom';
  src: url('assets/fonts/custom-hydronos.otf') format('opentype');
}

// .home-container::-webkit-scrollbar {
//   width: 8px !important;
//   height: 8px !important;
//   border-radius: 30px !important;
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }


/**
  Shared background, text, and colors
**/

.bg-light {
  background-color: #f0f0f0!important;
}

.bg-darker{
  background-color: #c5c5c5!important;

}

.hide{
  display: none;
}

.title-spacer{
  border-bottom: 1px solid #acacac;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.title-spacer-left{
  border-bottom: 1px solid #acacac;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.generic-header-text{
  font-family: 'Custom';
  font-size: 2.1rem;
  color: black;
  padding-bottom: 1rem;
  line-height: 45px;
}

.small-header-text{
  font-family: 'Custom';
  font-size: 1.7rem;
  color: black;
  padding-bottom: 1rem;
  line-height: 35px;
}

.accent-body-text-strong{
  font-family: Roboto, sans-serif !important;
  color: var(--darkAccentText) !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  line-height: 40px !important;
}

.accent-body-text{
  font-family: Roboto, sans-serif !important;
  color: var(--darkAccentText) !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  line-height: 35px !important;
}

.strong-body-text{
  font-family: Roboto, sans-serif !important;
  color: var(--darkAccentText) !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  line-height: 35px !important;
}

.accent-body-text-small{
  font-family: Roboto, sans-serif;
  color: var(--darkAccentText);
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
}

.body-text{
  font-family: Roboto, sans-serif;
  color: black;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 25px;
}

.body-text-small{
  font-family: Roboto, sans-serif;
  color: black;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
}


/**
Shared page header css

**/

.page-header-container {
  display: grid;
  height: calc(25vh - 60px);
  min-height: 100px;
  background-color: rgba(0, 0, 0, 0.678);
  position: relative;
}

.page-header-img {
  background: url(/assets/img/bg-main-large.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: calc(25vh - 60px);
  min-height: 100px;
  opacity: 0.5;
}

.page-header-text-div {
  display: grid;
  align-items: center;
  justify-self: center;
  width: 70%;
  height: 100%;
  position: absolute;
}

.page-header-text {
  color: white;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
}
@media (min-width: 1300px) {
  .page-header-text {
      color: white;
      font-size: 4rem;
      font-weight: 400;
      line-height: 1.5;
  }
}

/**
End shared header css
**/

/**
  Home page shared css
  Blurb
  **/


.blurb-container {
  display: grid;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  //grid-template-rows: repeat(2, 1fr);
}


.blurb-text-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 100%;
  padding: 2rem 4rem;
}

@media (min-width: 1200px) {
  .blurb-text-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100%;
    padding: 5rem 10rem;
  }
}


.blurb-text-div{

}

.blurb-more-div{
  display: grid;
  margin: auto;
  padding-top: 40px;
}

.blurb-img {
  //background: url(/assets/img/satellite.jpg) no-repeat center center;
}

.img-1 {
  // background: url(/assets/img/satellite.jpg) no-repeat center center;
  // background-size: cover;
  // width: 100%;
  // height:100%;
  display: none;
  height: 0px;
  width: 0px;
}

.bar-img{
  display: none;
  height: 0px;
  width: 0px;
}

.bar-img-left{
  background-size: cover;
  /* max-height: 350px; */
  /* width: auto; */
  display: flex;
  margin: auto;
  margin-top: 40px;
  /* width: 200px; */
  max-width: 90%;
  height: auto;
  
}


.img-1-mobile {
  //background: url(/assets/img/satellite.jpg) no-repeat center center;
  background: url(/assets/img/stock/windmills.jpg) no-repeat bottom center;

  background-size: cover;
  width: 100%;
  height: 100%;
}

.bar-img-mobile {
  background-size: cover;
  display: flex;
  margin: auto;
  margin-top: 20px;
  max-width: 90%;
  height: auto;
}

.img-bar-div-mobile{
  background-size: cover;
  width: 100%;
  height: 100%;
  //max-height: 400px;
  object-fit: cover;
}

.img-bar-div {
  // background: url(/assets/img/satellite.jpg) no-repeat center center;
  // background-size: cover;
  // width: 100%;
  // height:100%;
  display: none;
  height: 0px;
  width: 0px;
}
.img-bar-div-left {
  background-size: cover;
  width: 100%;
  height: 100%;
  //max-height: 400px;
  object-fit: cover;
}

.img-2 {
  background: url(/assets/img/stock/satellite.jpg) no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.img-3 {
  background: url(/assets/img/agri.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 1100px) {
  /* Replace with 360 or whatever you intend to */
  .blurb-container {
    display: grid;
    min-height: 30vh;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 00px;
    // padding-left: 20rem;
    // padding-right: 20rem;
  }

  .img-1 {
    background: url(/assets/img/stock/windmills.jpg) no-repeat bottom center;
    
    background-size: cover;
    width: 100%;
    height: 100%;
    display: grid;
  }

  .img-bar-div-mobile{
    display: none;
    height: 0px;
    width: 0px;
  }
  .img-bar-div{
    display: grid;
    //height: 100%;
    width: 100%;
    height: 100%;
    padding: 5rem 5rem;
  }

  .img-bar-div-left{
    display: grid;
    //height: 100%;
    width: 100%;
    height: 100%;
    padding: 5rem 5rem;
    max-height: fit-content;
  }

  .bar-img{
    max-width: 100%;
    height: auto;
    margin: auto;
    background-size: cover;
    width: 100%;
    display: flex;

  }

  .bar-img-left{
    background-size: cover;
    width: 100%;
    height: 100%;
    display: grid;
    margin-top: 0px;
    max-height: fit-content;
  }

  .img-1-mobile {
    display: none;
    height: 0px;
    width: 0px;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


//logo styling
.logo-container{
  //display: grid;
  //height: 200px;
  background-color: rgb(218 218 218);
  display: grid;
  min-height: 20vh;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.logo-wrapper{
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        flex-wrap: wrap;
}

.logo-text{
  align-items: center;
  justify-content: center;
  display: grid;
}


.logo-div{
  width: 150px;
  display: flex;
  margin: auto;
  padding: 1rem;
}

.logo-div-princeton{
  width: 130px;
  display: flex;
  margin: auto;
  padding: 1rem;
}

.logo-div-nfs{
  width: 80px;
  display: flex;
  margin: auto;
  padding: 1rem;
}
@media (min-width: 1200px) {
  .logo-wrapper{
    display: grid;
    width: 80%;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: 1fr;
    margin:auto;
  }

  .logo-div{
      width: 180px;
      display: flex;
      margin: auto;
  }
  .logo-div-princeton{
    width: 150px;
    display: flex;
    margin: auto;
    padding: 1rem;
  }
  .logo-div-nfs{
      width: 110px;
      display: flex;
      margin: auto;
  }
}



.logo-img{
  width: 100%;
}

//end logo styling

//blog styling
.blog-main-container{
  display: grid;
  min-height: 40vh;
  //background-color: rgb(177, 204, 104);
}

.blog-bar-header{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:2rem;
  //grid-template-columns: 1fr;
}

.blog-header-container {
  display: grid;
  height: calc(25vh - 60px);
  background-color: rgba(0, 0, 0, 0.678);
  position: relative;
}

// .blog-header-img {
//   //background: url(/assets/img/bg-main-large.jpg) no-repeat center center;
//   background-color: var(--accentText);
//   background-size: cover;
//   position: relative;
//   width: 100%;
//   height: calc(25vh - 60px);
//   opacity: 0.5;
// }

.blog-header-text-div {
  display: grid;
  align-items: center;
  justify-self: center;
  width: 70%;
  height: 100%;
  position: absolute;
}

.blog-header-text {
  color: white;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
}


.blog-main{
  display: grid;
  background-color: cadetblue;
  //overflow-y: auto;
  height: calc(100% - 60px);
}

.blog-header-div{
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.blog-wrapper{
  display: grid;
  align-items: start;
  justify-content: center;
  padding: 5rem 0px;
}


.blog-container{
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: repeat(3,1fr); 
  padding: 0px 00vw 0px 0vw;
  grid-gap:20px;
  //background-color: #fff;
}

.blog-container-featured{
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: repeat(3,1fr); 
  padding: 0px 00vw 0px 0vw;
  grid-gap:20px;
  //background-color: #fff;
}

@media (min-width: 1300px) {
  /* Replace with 360 or whatever you intend to */
  .blog-container {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      padding: 0px 10vw 0px 10vw;
      grid-gap:30px;

      // padding-left: 20rem;
      // padding-right: 20rem;
  }
  .blog-container-featured {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    padding: 0px 10vw 0px 10vw;
    grid-gap:30px;

    // padding-left: 20rem;
    // padding-right: 20rem;
}
  .blog-header-text {
      color: white;
      font-size: 4rem;
      font-weight: 400;
      line-height: 1.5;
  }
  
}


.blog-box{
  height: 450px;
  width: 350px;
  background-color: rgb(203, 203, 203);
  //background-color: chocolate;
  box-shadow: rgba(0, 0, 0, 0.26) 0px 10px 36px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  cursor:pointer;
}

.blog-box:hover{
  scale: 1.03;
}

.blog-img-div{
  height: 200px;
  width: 100%;
}

.blog-img{
  height: 100%;
  width: 100%;
  //padding: 5px;
}

.blog-title{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
}

.blog-title-text{
  font-size: 1.5rem;
  display: grid;
  align-items: center;
  justify-content: center;
}

.blog-body{
  min-height: 250px;
  width: 100%;
  padding: 5px;
  display: grid;
}

.blog-blurb{
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 10px 1rem 10px 1rem;
}

.blog-more-div{
 display: grid;
 align-items: center;
 justify-self: center;
}

.blog-footer{
  display: flex;
  width: 100%;
  font-size: 0.7rem;
  align-items: end;
}

.blog-tags{
  margin-right: auto;
}

.blog-tag{
  color: black !important;
  opacity: 1 !important;
  margin-right: 5px;
}

.blog-date{
  margin-left: auto;
}

.blog-spacer{
  border-bottom: 1px solid #acacac;
  margin-left: 15px;
  margin-right: 15px;

}


// end blog styling

.elevated{
  box-shadow: rgba(0, 0, 0, 0.26) 0px 10px 36px 0px;
}

.elevated-border{
  box-shadow: rgba(0, 0, 0, 0.26) 0px 10px 36px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;

}


//End Markdown styling