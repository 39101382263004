// Mixins
// Background Cover Mixin
@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Font Mixins
@mixin body-font {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
