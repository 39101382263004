header.masthead {
  position: relative;
  background-color: $gray-800;
  background: url("/assets/img/bg-main-large.jpg") no-repeat center center;
  @include background-cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
  .overlay {
    position: absolute;
    background-color: $gray-900;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
  }
  h1 {
    font-size: 2rem;
  }
  @media (min-width: 768px) {
    padding-top: 12rem;
    padding-bottom: 12rem;
    h1 {
      font-size: 3rem;
    }
  }
}
