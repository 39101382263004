// Core variables and mixins
@import "variables.scss";
@import "mixins.scss";
// Global CSS
@import "global.scss";
// Components
@import "masthead.scss";
@import "showcase.scss";
@import "icons.scss";
@import "testimonials.scss";
@import "call-to-action.scss";
@import "footer.scss";


.btn-teal{
    color: #fff;
    background-color:#19B6BE !important;
    border-color:#19B6BE !important;
  }