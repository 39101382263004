.call-to-action {
  position: relative;
  background-color: $gray-800;
  background: url("/assets/img/bg-main-large.jpg") no-repeat center center;
  @include background-cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
  .overlay {
    position: absolute;
    background-color: $gray-900;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
  }
}
