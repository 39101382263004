body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include body-font;
  font-weight: 700;
}
