.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
  .features-icons-item {
    max-width: 20rem;
    .features-icons-icon {
      height: 7rem;
      i,fa-icon  {
        font-size: 4.5rem;
        color:#19B6BE !important;
      }
    }
    // &:hover {
    //   .features-icons-icon {
    //     i,fa-icon  {
    //       font-size: 5rem;
    //       color:#19B6BE;
    //     }
    //   }
    // }
  }
  .features-icons-item-small {
    max-width: 20rem;
    margin-top: 40px;
    .features-icons-icon-small {
      height: 4rem;
      i,fa-icon  {
        font-size: 2.5rem;
        //color:#19B6BE !important;
      }
    }
    // &:hover {
    //   .features-icons-icon {
    //     i,fa-icon  {
    //       font-size: 5rem;
    //       color:#19B6BE;
    //     }
    //   }
    // }
  }
}


